/** @deprecated - this is moved to libs */
export enum SalesfunnelRoutes {
    CarInsurance = 'autoverzekering',
    MopedInsurance = 'brommerverzekering',
    MotorInsurance = 'motorverzekering',
    BicycleInsurance = 'fietsverzekering',
    CaravanInsurance = 'caravanverzekering',
    HomeInsurance = 'woonverzekering',
    PetInsurance = 'dierenverzekering',
    TravelInsurance = 'reisverzekering',
    LiabilityInsurance = 'aansprakelijkheidsverzekering',
    LegalAssistance = 'rechtsbijstandverzekering',
    Accidents = 'ongevallenverzekering',
    Calculate = 'berekenen',
    CalculateCarInsurance = 'berekenen-autoverzekering',
    CalculateMopedInsurance = 'berekenen-brommerverzekering',
    CalculateMotorInsurance = 'berekenen-motorverzekering',
    CalculateCaravanInsurance = 'berekenen-caravanverzekering',
    CalculateBicycleInsurance = 'berekenen-fietsverzekering',
    CalculatePetInsurance = 'berekenen-dierenverzekering',
    CalculateHomeInsurance = 'berekenen-woonverzekering',
    CalculateTravelInsurance = 'berekenen-reisverzekering',
    CalculateLiabilityInsurance = 'berekenen-aansprakelijkheidsverzekering',
    CalculateLegalAssistance = 'berekenen-rechtsbijstandverzekering',
    CalculateAccidents = 'berekenen-ongevallenverzekering',
    Thanks = 'bedankpagina',
    Activate = 'activeren',
    InsuranceCoverage = 'dekking-en-premie',
    Insure = 'verzekeren',
    PersonalData = 'eigen-gegevens',
    Summary = 'afronden',
}
